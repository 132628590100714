import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { getFileType } from '../../../../utils/helpers'
import { Row, Col } from 'react-bootstrap'
import { alertError } from '../../../../utils/logger'
import { formattedValueNumber } from '../../../../utils/numbers'

const EditPortionDialog = (props) => {
    const { title, open, data, onSave, setOpen } = props;
    const [portion, setPortion] = useState(getEmptyPortion());
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (!data || !open) setPortion(getEmptyPortion());
        else setPortion(data)
    }, [data, open])

    function getEmptyPortion() {
        return {
            name: null,
        }
    }

    const handleChange = (element, lang, isNumber) => (event) => {
		const { value } = event.target;
        if (event.target.value === " ") return;
        let formattedValue = formattedValueNumber(value, isNumber);
		setPortion({ ...portion, [element]: formattedValue });
        setRefresh(true);
	};

    return (
        <Dialog disableBackdropClick={true}
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="table-dialog"
        >
        <DialogTitle id="table-dialog">{title}</DialogTitle>
        <DialogContent>
            <Row>
                <Col sm={12}>
                    <TextField
                        id={`name`}
                        label="Name"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChange('name', false)}
                        value={portion?.name}
                        margin="normal"
                        placeholder="Enter portions name..."
                        variant="standard"
                        type="text"
                    />
                </Col>
                <Col sm={12}>
                    <TextField
                        id={`grams`}
                        label="Grams"
                        InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }}
                        onChange={handleChange('grams', false, true)}
                        value={portion?.grams}
                        margin="normal"
                        variant="standard"
                        type="number"
                    />
                </Col>
            </Row>
                    
        </DialogContent>
        <DialogActions>
            <Button
                variant="outlined"
                style={{ marginRight: "15px" }}
                onClick={() => {
                    setOpen(false);
                    setPortion(getEmptyPortion())
                }}
            >
                Close
            </Button>
            <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                    if (!portion?.name) {
                        alertError({
                            error: "Portion name is needed",
                            customMessage: "Empty field"
                        })
                    } else {
                        setOpen(false);
                        onSave({...portion, grams: !portion?.grams ? 0 : portion.grams});
                        setPortion(getEmptyPortion())
                    }
                }}
            >
                Save
            </Button>
        </DialogActions>
        </Dialog>
    )
}
export default EditPortionDialog;
