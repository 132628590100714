import {
	Button,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { alertError } from "../../../../../utils/logger";
import { useSkeleton } from "../../../../hooks/useSkeleton";
import { getCompetitions } from '../../../../../api/competition'
import { getUserCompetitionsByUserId } from "../../../../../api/userCompetitions";
import Table, { buttonsStyle } from "../../../../components/tables/table";
import { Delete, Edit, Visibility } from "@material-ui/icons";
import ViewPatientCompetition from "../ViewPatientCompetition";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyUserCompetitions() {
	return {
		competitions: null
	};
}

export default function EditPatientsCompetitions(props) {
	const { userId, changes, setChanges, userCompetitions, setUserCompetitions, tramsXuser, setTramsXuser, patient } = props;
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
	const history = useHistory();

	const [competitions, setCompetitions] = useState([]);

	const [selectedCompetition, setSelectedCompetition] = useState(null);
	const [openViewPatientCompetition, setOpenViewPatientCompetition] = useState(false);

	const [refresh, setRefresh] = useState(false);
	
	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (competitions.length === 0)
			getCompetitions()
			.then((res) => {
				if (res.status === 200) {
					setCompetitions(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get competitions.",
				});
			});

		
		if (userCompetitions === null && userId)
			getUserCompetitionsByUserId(userId)
				.then((res) => {
					if (res.status === 200) {
						setUserCompetitions(res.data);
						disableLoadingData();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get user competitions.",
					});
				});
	}, [userId, disableLoadingData])

	useEffect(() => {
		setRefresh(false);
	}, [refresh])

	const competitionsTable = [
		{
			dataField: "competitionName",
			text: "name"
		},
		{
			dataField: "date",
			text: "date",
			headerAlign: "center",
			align: "center"
		},
		{
			dataField: "competitionId",
			text: "",
			formatter: competitionButtonsFormatter,
			align: "right"
		}
	]

	function competitionButtonsFormatter(cell) {
		const index = cell;
		const competition = userCompetitions?.competitions?.find(
			(x) => x.competitionId === cell
		)

		return (
			<>
				{cell != 0 && (
					<> 
						<Tooltip title="Edit">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setOpenViewPatientCompetition(true);
									setSelectedCompetition(competition);
								}}
							>
								<Visibility />
							</Button>
						</Tooltip>
					</>
				) 
				}
				
			</>
		)
	}

	function getCompetitionsData(userCompetitions) {
		let data = [];

		for (let i = 0; i < userCompetitions?.competitions?.length; ++i) {
			let elem = {}
			const act = userCompetitions.competitions[i];
			
			elem._id = act._id;
			elem.competitionId = act.competitionId;
			elem.competitionName = act.competitionName;
			elem.date = dateFormatter(act.date);

			data.push(elem);
		}

		return data;
	}

	function dateFormatter(dateString) {
		const date = new Date(dateString);

		const day = String(date.getUTCDate()).padStart(2, '0');
		const month = String(date.getUTCMonth() + 1).padStart(2, '0');
		const year = date.getUTCFullYear();
		const hours = String(date.getUTCHours()).padStart(2, '0');
		const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    	return `${day}-${month}-${year} at ${hours}:${minutes}`;
	}

	if (isLoadingData) return <ContentSkeleton />;
	else if (openViewPatientCompetition) 
		return <ViewPatientCompetition
			dataComp={selectedCompetition}
			competitions={competitions}
			userId={userId}
			setOpen={setOpenViewPatientCompetition}
			userCompetitions={userCompetitions}
			setUserCompetitions={setUserCompetitions}
			tramsXuser={tramsXuser}
			setTramsXuser={setTramsXuser}
			changes={changes}
			setChanges={setChanges}
			patient={patient}
		/>
	else
		return (
			<>
				<Card>
					<CardHeader title="Competitions">
						<CardHeaderToolbar>
							
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{!refresh && (
							<Table 
								columns={competitionsTable}
								data={getCompetitionsData(userCompetitions)}
							/>
						)}
					</CardBody>
					
                    <h4>FCA</h4>
                </Card>
			</>
		);
}
