import { Box, Button, FormControlLabel, Switch, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { changeStatusFood, deleteFood, getFoods } from "../../../../api/food";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
} from "../../../components/tables/table";
import FiltersCard from "../../../components/filters/Filter";
import MyAutocomplete from "../../../components/MyAutocomplete";
import { getFoodFamilies } from "../../../../api/foodFamily";

function getData(foods) {
	let data = [];
	for (let i = 0; i < foods.length; ++i) {
		const elem = {};
		const actF = foods[i];
		elem.name = getNonEmpty(actF.fullName);
		elem.foodFamily = getNonEmpty(actF.foodFamily?.fullName);
		elem.createdAt = actF.createdAt;
		elem.id = actF._id;
		elem.active = actF.active;
		elem.kcals = actF?.kcals || 0;
		elem.carbohydrates = actF?.carbohydrates || 0;
		elem.proteins = actF?.proteins || 0;
		elem.fats = actF?.fats || 0;
		elem.fiber = actF?.fiber || 0;
		data = data.concat(elem);
	}

	return data;
}

function getDataFoodFamilies(foodFamilies) {
	let data = [];
	for (let i = 0; i < foodFamilies.length; ++i) {
		const elem = {};
		const actF = foodFamilies[i];
		elem._id = actF._id;
		elem.name = actF.fullName.es;
		data = data.concat(elem);
	}

	return data;
}

const initialFilters = {
	active: false,
	foodFamily: null
}

export default function FoodsPage() {
	const [data, setData] = useState([]);
	const [foodId, setFoodId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [collapsed, setCollapsed] = useState(true);
	const [filteredData, setFilteredData] = useState([]);
	const [filterOptions, setFilterOptions] = useState(initialFilters);
	const [foodFamilies, setFoodFamilies] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	
	useEffect(() => {
		getFoods()
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
					setFilteredData(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get foods.",
				});
			});
		getFoodFamilies()
			.then((res) => {
				if (res.status === 200) {
					setFoodFamilies(getDataFoodFamilies(res.data));
				}
			})
			.catch((error) => {
				if (error.status !== 404) {
					alertError({
						error: error,
						customMessage: "Could not get food families"
					})
				}
			})
	}, [refresh]);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-food/" + cell)}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setFoodId(elem);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "red" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setFoodId(cell);
									setOpenConfirmDialog(2);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{
			dataField: "name",
			text: "Full name",
			headerStyle: { width: '350px' },
			headerAlign: 'left',
			align: 'left',
			sort: true,
		},
		{
			dataField: "kcals",
			text: "KCAL",
			headerAlign: 'center',
			align: 'center',
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "carbohydrates",
			text: "CHO",
			headerAlign: 'center',
			align: 'center',
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "proteins",
			text: "PRO",
			headerAlign: 'center',
			align: 'center',
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "fats",
			text: "fat",
			headerAlign: 'center',
			align: 'center',
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "fiber",
			text: "FIBER",
			headerAlign: 'center',
			align: 'center',
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{ 
			dataField: "id", 
			text: "", 
			headerStyle: {width: '150px'},
			formatter: buttonFormatter 
		},
	];


	const handleSearch = async () => {
		if (!data.length) return;
		setFilteredData(data.filter(item => {
			let filter = true;
			if (filterOptions.active) filter = filter && item.active;
			if (filterOptions.foodFamily) filter = filterOptions.foodFamily === item?.foodFamily?._id;
			
			if (filter) return item;
			return false;
		}))
	}

	const handleClearFilters = () => {
		setFilterOptions(initialFilters);
		setRefresh(true);
	}

	const renderFiltersContent = () => {
		return <>
			<br/>
			<FormControlLabel 
				style={{marginTop: '16px'}}
				control={<Switch checked={filterOptions.active} onChange={() => setFilterOptions({...filterOptions, active: !filterOptions.active})} name="checkActive" />}
				label = {filterOptions.active ? "Only active" : "All active/inactive"}
			/>

			<FormControlLabel 
				control={
					<MyAutocomplete 
						options={foodFamilies}
						getOptionLabel={(option) => option.name}
						value={
							foodFamilies.find((x) => x._id === filterOptions.foodFamily) 
							|| null
						}
						onChange={(event, selected) => {
							setFilterOptions({...filterOptions, foodFamily: selected?._id})
						}}
						placeholder="Selected food family"
						label={""}
						style={{flex: 1, marginLeft: '20px'}}
						/>
					}
				style={{width: "300px"}}
				// label = {filterOptions?.foodFamily ? filterOptions.foodFamily : "Select"}
			/>
		</>
	}

	return (
		<>
			<Card>
				<CardHeader title="Foods list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => history.push("/edit-food")}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<FiltersCard 
						filtersContent={renderFiltersContent}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>
					<Table data={getData(filteredData, data)} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${
							foodId?.active ? "disable" : "enable"
						} this food?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusFood(foodId._id, !foodId?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												foodId?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Food ${
												foodId?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											foodId?.active
												? "disable"
												: "enable"
										} food.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={"Are you sure you want to remove this food?"}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteFood(foodId)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Food removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: "Could not remove food.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
