/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LocalDining from '@material-ui/icons/LocalDining';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import KitchenIcon from '@material-ui/icons/Kitchen';
import DescriptionIcon from '@material-ui/icons/Description';
import LanguageIcon from '@material-ui/icons/Language';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SendIcon from '@material-ui/icons/Send';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import SportsIcon from '@material-ui/icons/Sports';
import SportsBaseball from '@material-ui/icons/SportsBaseball';
import { shallowEqual, useSelector } from 'react-redux'

export function AsideMenuList({ layoutProps }) {
	const user = useSelector((store) => store.authentication?.user, shallowEqual)
	const location = useLocation()
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
          'menu-item-active'} menu-item-open menu-item-not-hightlighted`
			: ''
	}

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				<li
					className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/dashboard">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
						</span>
						<span className="menu-text">Dashboard</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">USERS</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				{user.role === 'admin' && <li
					className={`menu-item ${getMenuItemActive('/admins', false)} ${getMenuItemActive('/edit-admin', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/admins">
						<span className="menu-icon">
							<VerifiedUserIcon/>
						</span>
						<span className="menu-text">Administrators</span>
					</NavLink>
				</li>}
				<li
					className={`menu-item ${getMenuItemActive('/patients', false)} ${getMenuItemActive('/edit-patient', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/patients">
						<span className="menu-icon">
							<AssignmentIndIcon/>
						</span>
						<span className="menu-text">Patients</span>
					</NavLink>
				</li>

				<li className='menu-section'>
					<h4 className='menu-text'>MANAGEMENT</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/visits', false)} ${getMenuItemActive('/edit-visit', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/visits">
						<span className="menu-icon">
							<ScheduleIcon/>
						</span>
						<span className="menu-text">Visits</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/calendar', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/calendar">
						<span className="menu-icon">
							<EventAvailableIcon/>
						</span>
						<span className="menu-text">Calendar</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/competitions', false)} ${getMenuItemActive('/edit-competition', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/competitions">
						<span className="menu-icon">
							<EmojiEventsIcon/>
						</span>
						<span className="menu-text">Competitions</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/structures', false)} ${getMenuItemActive('/edit-structure', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/structures">
						<span className="menu-icon">
							<BeenhereIcon/>
						</span>
						<span className="menu-text">Structures</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/food-plates', false)} ${getMenuItemActive('/edit-food-plate', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/food-plates">
						<span className="menu-icon">
							<RestaurantIcon/>
						</span>
						<span className="menu-text">Recipes</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/foods', false)} ${getMenuItemActive('/edit-food', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/foods">
						<span className="menu-icon">
							<KitchenIcon/>
						</span>
						<span className="menu-text">Foods</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/sport-foods', false)} ${getMenuItemActive('/edit-sport-food', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/sport-foods">
						<span className="menu-icon">
							<SportsBaseball/>
						</span>
						<span className="menu-text">Sport foods</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/activities', false)} ${getMenuItemActive('/edit-activity', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/activities">
						<span className="menu-icon">
							<DirectionsRunIcon/>
						</span>
						<span className="menu-text">Activities</span>
					</NavLink>
				</li>
				
				<li className='menu-section'>
					<h4 className='menu-text'>COMMUNICATION</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/sendings', false)} ${getMenuItemActive('/edit-sending', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/sendings">
						<span className="menu-icon">
							<SendIcon/>
						</span>
						<span className="menu-text">Segmented sendings</span>
					</NavLink>
				</li>

				<li className='menu-section'>
					<h4 className='menu-text'>MISCELLANY</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				{user.role === 'admin' && (
					<>
						<li
							className={`menu-item ${getMenuItemActive('/notes', false)} ${getMenuItemActive('/edit-note', false)}`}
							aria-haspopup="true"
						>
							<NavLink className="menu-link" to="/notes">
								<span className="menu-icon">
									<DescriptionIcon/>
								</span>
								<span className="menu-text">Notes</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive('/food-families', false)} ${getMenuItemActive('/edit-food-family', false)}`}
							aria-haspopup="true"
						>
							<NavLink className="menu-link" to="/food-families">
								<span className="menu-icon">
									<MenuBookIcon/>
								</span>
								<span className="menu-text">Food families</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive('/products-patient', false)} ${getMenuItemActive('/edit-products-patient', false)}`}
							aria-haspopup="true"
						>
							<NavLink className="menu-link" to="/products-patient">
								<span className="menu-icon">
									<LocalDining/>
								</span>
								<span className="menu-text">Products patient</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive('/competition-types', false)} ${getMenuItemActive('/edit-competition-type', false)}`}
							aria-haspopup="true"
						>
							<NavLink className="menu-link" to="/competition-types">
								<span className="menu-icon">
									<SportsIcon/>
								</span>
								<span className="menu-text">Competition types</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive('/visit-types', false)} ${getMenuItemActive('/edit-visit-type', false)}`}
							aria-haspopup="true"
						>
							<NavLink className="menu-link" to="/visit-types">
								<span className="menu-icon">
									<DateRangeIcon/>
								</span>
								<span className="menu-text">Visit type</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/languages',
								false
							)} ${getMenuItemActive('/edit-language', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/languages'>
								<span className='menu-icon'>
									<LanguageIcon />
								</span>
								<span className='menu-text'>Languages</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive('/texts', false)} ${getMenuItemActive('/edit-text', false)}`}
							aria-haspopup="true"
						>
							<NavLink className="menu-link" to="/texts">
								<span className="menu-icon">
									<DescriptionIcon/>
								</span>
								<span className="menu-text">Texts</span>
							</NavLink>
						</li>
					</>
				)}
			</ul>

			{/* end::Menu Nav */}
		</>
	)
}
