import { API, authClient } from "../index";

export const getSportFoods = () => {
	return authClient().get(`${API}/sport-food?getDisabled=true`);
};

export const getSportFoodById = (id) => {
	return authClient().get(`${API}/sport-food/${id}`);
};

export const deleteSportFood = (id) => {
	return authClient().delete(`${API}/sport-food/${id}`);
};

export const postSportFood = async (food) => {
	return authClient().post(`${API}/sport-food`, food);
};

export const updateSportFood = async (id, food) => {
	return authClient().put(`${API}/sport-food/${id}`, food);
};

export const changeStatusSportFood = async (id, active) => {
	return authClient().put(`${API}/sport-food/change-status/${id}`, {
		active,
	});
};
