import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	createMuiTheme,
	Tooltip
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, { buttonsStyle } from "../../../components/tables/table";
import {
	deleteFood,
	getFoodById,
	postFood,
	updateFood,
} from "../../../../api/food";
import { getFoodFamilies } from "../../../../api/foodFamily";
import { checkIsEmpty, formatFloat, getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { Col, Row } from "react-bootstrap";
import EditPortionDialog from "../../../components/dialogs/food/EditPortionDialog";
import { Edit, Delete } from "@material-ui/icons";
import MyAutocomplete from "../../../components/MyAutocomplete";
import { formattedValueNumber } from "../../../../utils/numbers";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyFood() {
	return {
		fullName: {},
		foodFamily: null,
		kcals: null,
		proteins: null,
		carbohydrates: null,
		fats: null,
		portionConsumed: null,
		fiber: null,
		ethanol: null,
		active: true,
		portions: []
	};
}

export default function EditFoodsPage() {
	const [foodFamilies, setFoodFamilies] = useState([]);
	const [food, setFood] = useState(getEmptyFood());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);

	const [selectedPortion, setSelectedPortion] = useState(null);
	const [openEditPortionsDialog, setOpenEditPortionsDialog] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const foodId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	
	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	const [changes, setChanges] = useState(-2);

	useEffect(() => {
		if (!foodId) setChanges(changes+2);
		else setChanges(changes+1);
	}, [food])

	useEffect(() => {
		setRefresh(false);
	}, [refresh])

	useEffect(() => {
		getFoodFamilies()
			.then((res) => {
				if (res.status === 200) {
					setFoodFamilies(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get food families.",
				});
			});
		if (!foodId) {
			disableLoadingData();
			return;
		}
		getFoodById(foodId)
			.then((res) => {
				if (res.status === 200) {
					setFood(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get food.",
				});
				history.push("/foods");
			});
	}, [foodId, disableLoadingData, history]);

	const portionsTable = [
		{
			dataField: "name",
			text: "name"
		},
		{
			dataField: "grams",
			text: "grams",
			align: "center",
			headerAlign: "center"
		},
		{
			dataField: "_id",
			text: "",
			align: "right",
			headerAlign: "right",
			formatter: portionsButtonsFormatter
		}
	];

	function portionsButtonsFormatter(cell) {
		const portion = food?.portions?.find((x) => x._id === cell)

		return (
			<>
				<Tooltip title="Edit portion">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenEditPortionsDialog(true);
							setSelectedPortion(portion);
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Delete portion">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => deletePortion(portion)}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		)
	}

	function deletePortion(portion) {
		let newPortions = [...food?.portions];

		const index = newPortions?.findIndex((x) => x._id === portion._id);

		if (index !== -1) {
			newPortions.splice(index, 1);
			for (let i = index; i < newPortions?.length; ++i) newPortions[i]._id--;
			setFood({...food, portions: newPortions});
			setRefresh(true);
		}
	}

	function saveFood() {
		if (checkIsEmpty(food.fullName))
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		if (!food?.foodFamily)
			return alertError({
				error: null,
				customMessage: "Food family is required.",
			});
		if (!foodId) {
			postFood(food)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Food successfully created.",
						});
						history.push("/foods");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save food.",
					});
				});
		} else {
			updateFood(foodId, food)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/foods");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	function reCalcKcals(food) {
		const newKcals = food.proteins * 4 + 
						food.carbohydrates * 4 + 
						food.fats * 9 +
						food.ethanol * 7;

		return newKcals;
	}

	const handleChange = (element, lang, isNumber) => (event) => {
		const { value } = event.target;
		if (value === " ") return;
		let formattedValue = formattedValueNumber(value, isNumber);
		if (lang) {
			if (!food[element]) food[element] = {};
			let newText = food[element];
			newText[lang] = value;
			setFood({ ...food, [element]: newText });
		} else {
			if (element === "portionConsumed") {
				const val = Number(value);
				if (val < 0 || value === "") formattedValue = "0";
				else if (val > 1) formattedValue = "1";
			}
			const newFood = {
				...food,
				[element]: formattedValue
			}
			const kcals = reCalcKcals(newFood);
			setFood({
				...food,
				[element]: formattedValue,
				kcals: kcals
			});
		}
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full name"
					value={(food.fullName && food.fullName[lang]) || ""}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
				/>
			</>
		);
	};

	function addNewPortion() {
		setOpenEditPortionsDialog(true);
		setSelectedPortion(null);
		setRefresh(true);
	}

	function getPortionsData(portions) {
		let data = [];

		for (let i = 0; i < portions?.length; ++i) {
			let elem = {};
			const act = portions[i];

			elem.name = act?.name;
			elem.grams = act?.grams;
			elem._id = act?._id;

			data.push(elem);
		}

		return data;
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
				<CardHeader title={food?._id?'Food: ' + (food.fullName?food.fullName.es:''):'New food'}>
					<div className="mt-5">
						<Button
							onClick={() => {
								if (changes > 0) setOpenConfirmDialog(1);
								else history.push("/foods");
							}}
							variant="outlined"
							style={{ marginRight: "20px" }}
						>
							Back
						</Button>
						<Button
							onClick={() => saveFood()}
							variant="outlined"
							color="primary"
							style={{ marginRight: "20px" }}
						>
							Save food
						</Button>
						<ConfirmDialog
							title={
								"Are you sure you want to go back? You will lose all your changes"
							}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								history.push("/foods")
							}}
						/>
						{foodId && user?.role.includes("admin") && (
							<>
								<MuiThemeProvider theme={theme}>
									<Button
										onClick={() => setOpenConfirmDialog(2)}
										variant="outlined"
										color="secondary"
										style={{ marginRight: "20px" }}
									>
										Delete food
									</Button>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											marginLeft: "auto",
										}}
									></div>
								</MuiThemeProvider>

								<ConfirmDialog
									title={
										"Are you sure you want to delete this food?"
									}
									open={openConfirmDialog === 2}
									setOpen={setOpenConfirmDialog}
									onConfirm={() => {
										deleteFood(foodId)
											.then((res) => {
												if (
													res.status === 204 ||
													res.status === 200
												) {
													alertSuccess({
														title: "Deleted!",
														customMessage:
															"Food deleted successfully",
													});
													history.push("/foods");
												}
											})
											.catch((error) => {
												alertError({
													error: error,
													customMessage:
														"Could not delete food.",
												});
											});
									}}
								/>
							</>
						)}
					</div>
				</CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<MyAutocomplete
							options={foodFamilies}
							getOptionLabel={(option) =>
								getNonEmpty(option.fullName)
							}
							value={
								foodFamilies?.find(
									(x) => x._id === food.foodFamily
								) || null
							}
							onChange={(event, selected) => {
								setFood({
									...food,
									foodFamily: selected?._id,
								});
							}}
							placeholder="Select food family"
							label={"Food family"}
						/>
						<br />
						<h4>For 100 grams of {food.fullName.es}:</h4>
						<Row>
							<Col sm={2}>
								<TextField
									id={`kcals`}
									label="KCAL"
									value={formatFloat(food?.kcals)}
									inputProps={{ readOnly: true }}
									required
									margin="normal"
									variant="standard"
									type="number"
									className="readonly"
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`carbohydrates`}
									label="CHO"
									value={food?.carbohydrates }
									onChange={handleChange("carbohydrates", null, true)}
									InputLabelProps={{
										shrink: true,
									}}
									required
									margin="normal"
									variant="standard"
									type="number"
									InputProps={{
										inputProps: {
											min: 0,
										},
									}}
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`proteins`}
									label="PRO"
									value={food?.proteins }
									onChange={handleChange("proteins", null, true)}
									InputLabelProps={{
										shrink: true,
									}}
									required
									margin="normal"
									variant="standard"
									type="number"
									InputProps={{
										inputProps: {
											min: 0,
										},
									}}
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`fats`}
									label="FAT"
									value={food?.fats }
									onChange={handleChange("fats", null, true)}
									InputLabelProps={{
										shrink: true,
									}}
									required
									margin="normal"
									variant="standard"
									type="number"
									InputProps={{
										inputProps: {
											min: 0,
										},
									}}
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`fiber`}
									label="FIBER"
									value={food?.fiber }
									onChange={handleChange("fiber", null, true)}
									InputLabelProps={{
										shrink: true,
									}}
									required
									margin="normal"
									variant="standard"
									type="number"
									InputProps={{
										inputProps: {
											min: 0
										},
									}}
								/>
							</Col>
							<Col sm={2}>
								<TextField
									id={`ethanol`}
									label="Ethanol"
									value={food?.ethanol }
									onChange={handleChange("ethanol", null, true)}
									InputLabelProps={{
										shrink: true,
									}}
									required
									margin="normal"
									variant="standard"
									type="number"
									InputProps={{
										inputProps: {
											min: 0,
										},
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<TextField
									id={`portionConsumed`}
									label="Edible portion"
									value={food?.portionConsumed }
									onChange={handleChange("portionConsumed", null, true)}
									InputLabelProps={{
										shrink: true,
									}}
									required
									margin="normal"
									variant="standard"
									type="number"
									InputProps={{
										inputProps: {
											min: 0, max: 1
										},
									}}
								/>
							</Col>
						</Row>
						
						<Row>
						  	<Col sm={12}>
								<TextField
									id={`notes`}
									label="Notes"
									value={(food?.notes) || ""}
									onChange={handleChange("notes")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="standard"
									placeholder="Enter notes..."
									multiline
									rows={1}
								/>
							</Col>
						</Row>
						<FormControlLabel
							control={
								<Checkbox
									checked={food.active}
									onChange={() =>
										setFood({
											...food,
											active: !food.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
					<CardHeader title="Portions"> 
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => addNewPortion()}
							>
								Add new
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{!refresh && (
							<Table
								columns={portionsTable}
								data={getPortionsData(food?.portions)}
							/>
						)}
					</CardBody>
					<EditPortionDialog 
						title={"Portion"}
						open={openEditPortionsDialog}
						setOpen={setOpenEditPortionsDialog}
						data={selectedPortion}
						onSave={(portion) => {
							let newPortions = [...food?.portions] || [];

							const index = newPortions?.findIndex((x) => x._id === portion._id)

							if (index !== -1) newPortions[index] = portion;
							else newPortions.push({...portion, _id: newPortions.length});

							setFood({...food, portions: newPortions});
							setRefresh(true);
						}}
					/>
				</Card>
			</>
		);
}
