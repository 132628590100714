import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { alertError, alertSuccess } from "../../../../../utils/logger";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
} from "../../../../components/tables/table";
import { changeStatusUserStructures, deleteUserStructures, getUserStructuresByUserId } from '../../../../../api/userStructures';
import { shallowEqual, useSelector } from "react-redux";
import EditPatientStructure from "./EditPatientStructure";
import { deleteAllBlocksRecipesFromBD, getDataBlocks } from "../../../../../utils/structuresCalcs";
import { formatFloat } from "../../../../../utils/helpers";
import { useSkeleton } from "../../../../hooks/useSkeleton";

export default function EditPatientStructures(props) {
    const { patientId, userStructures, setUserStructures, patient } = props;
    const [refresh, setRefresh] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
    const [openEditStructure, setOpenEditStructure] = useState(false);
    const [columnsStructures, setColumnsStructures] = useState([]);
    const [selectedStructure, setSelectedStructure] = useState(null);
    const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
    const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();


    useEffect(() => {
		if (!openEditStructure) refreshStructures(); 
	}, [openEditStructure]);

    useEffect(() => {
        setRefresh(false);
    }, [refresh])

    function refreshStructures() {
        getUserStructuresByUserId(patientId)
            .then((res) => {
                if (res.status === 200) {
                    const structures = res.data;
                    const newStructures = structures.map(structure => ({ ...structure }));
                    setUserStructures(newStructures);
                    getData(newStructures);
                }
            })
            .catch((error) => {
                if (error?.request?.status && error.request.status != 404) {
                    alertError({
                        error: error,
                        customMessage: "Could not get structures.",
                    });
                }
            });
    }

    async function getData(structures) {
        let data = [];
        for (let i = 0; i < structures.length; ++i) {
            const elem = {};
            const actS = structures[i];
            elem.fullName = actS.fullName.es;
            elem.createdAt = actS.createdAt;
            elem._id = actS._id;
            elem.active = actS.active;

			const calcs = await getDataBlocks(actS?.blocks || [], patient);
			elem.kcals = calcs?.kcals;
			elem.carbohydrates = calcs?.carbohydrates;
			elem.proteins = calcs?.proteins;
			elem.fats = calcs?.fats;
			elem.fiber = calcs?.fiber;
            data = data.concat(elem);
        }
		setColumnsStructures([...data]);
		disableLoadingData();
        setRefresh(true);
    }

    const columns = [
		{ dataField: "fullName", text: "Full Name", sort: true },
		{
			dataField: "kcals",
			text: "kcal",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat
		},
		{
			dataField: "carbohydrates",
			text: "cho",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat
		},
		{
			dataField: "proteins",
			text: "prot",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat
		},
		{
			dataField: "fats",
			text: "fat",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat
		},
		{
			dataField: "fiber",
			text: "fiber",
			headerAlign: 'center',
			align: 'center',
			formatter: formatFloat,
		},
		{ 
            dataField: "_id", 
            text: "", 
            align: 'right', 
			headerStyle: {width: '150px'},
            formatter: buttonFormatter 
        },
	];

    function buttonFormatter(cell) {
		const elem = userStructures.find((item) => item._id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() =>{
                            setOpenEditStructure(true);
                            setSelectedStructure(elem);
						}}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setSelectedStructure(elem);
									setOpenConfirmDialog(2);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "red" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setSelectedStructure(elem);
									setOpenConfirmDialog(1);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

    async function deleteStructure(structure) {
		const res = await deleteAllBlocksRecipesFromBD(structure);
		if (!res) return;
        deleteUserStructures(structure?._id)
            .then((res) => {
                if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                        title: "Deleted!",
                        customMessage:
                            "Structure deleted successfully",
                    });
                    refreshStructures();
                }
            })
            .catch((error) => {
                alertError({
                    error: error,
                    customMessage:
                        "Could not delete structure?.",
                });
            });
	}

    if (isLoadingData) return (<ContentSkeleton />)
    else if (openEditStructure) return (
        <EditPatientStructure
            structure={selectedStructure}
            setStructure={setSelectedStructure}
            userId={patientId}
            setOpen={setOpenEditStructure}
            patient={patient}
        />
    )
    else return (
        <>
            <Card>
                <CardHeader title="User Structures"> 
                <CardHeaderToolbar>
                    <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                        setOpenEditStructure(true);
                        setSelectedStructure(null);
                    }}
                    >
                    Add new
                    </button>
                </CardHeaderToolbar>
                </CardHeader>
                <ConfirmDialog
                    title={
                    "Are you sure you want to delete the structure?"
                    }
                    open={openConfirmDialog === 1}
                    setOpen={setOpenConfirmDialog}
                    onConfirm={async () => {
                        deleteStructure(selectedStructure)
                    }}
                />

                <ConfirmDialog
                    title={`Are you sure you want to ${
                        selectedStructure?.active ? "disable" : "enable"
                    } this structure?`}
                    open={openConfirmDialog === 2}
                    setOpen={setOpenConfirmDialog}
                    onConfirm={() => {
                        changeStatusUserStructures(
                            selectedStructure._id,
                            !selectedStructure?.active
                        )
                            .then((res) => {
                                if (res.status === 200) {
                                    refreshStructures();
                                    alertSuccess({
                                        title: `${
                                            selectedStructure?.active
                                                ? "Disabled!"
                                                : "Enabled!"
                                        }`,
                                        customMessage: `Structure ${
                                            selectedStructure?.active
                                                ? "disabled"
                                                : "enabled"
                                        } successfully`,
                                    });
                                    setRefresh(true);
                                }
                            })
                            .catch((error) => {
                                alertError({
                                    error: error,
                                    customMessage: `Could not ${
                                        selectedStructure?.active
                                            ? "disable"
                                            : "enable"
                                    } structure.`,
                                });
                            });
                    }}
                />
                <CardBody>
                    {!refresh && <Table data={columnsStructures} columns={columns} />}
                </CardBody>
            </Card>
        </>
    )
}


