import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { Delete, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import {
	deleteActivity,
	getActivityById,
	postActivity,
	updateActivity,
} from "../../../../api/activity";
import { checkIsEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { Container, Row, Col } from 'react-bootstrap';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyActivity() {
	return {
		fullName: {},
		moderate: "0",
		vigorous: "0",
		soft: "0",
		active: true
	};
}

export default function EditActivitiesPage() {
	const [activity, setActivity] = useState(getEmptyActivity());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);

	const activityId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	const [changes, setChanges] = useState(-2);

	useEffect(() => {
		if (!activityId) setChanges(changes+2);
		else setChanges(changes+1);
	}, [activity])

	useEffect(() => {
		if (!activityId) {
			disableLoadingData();
			return;
		}
		getActivityById(activityId)
			.then((res) => {
				if (res.status === 200) {
					setActivity(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get activity.",
				});
				history.push("/activities");
			});
	}, [activityId, disableLoadingData, history]);

	function saveActivity() {
		const moderate = activity.moderate;
		const vigorous = activity.vigorous;
		const soft = activity.soft;
		if (checkIsEmpty(activity.fullName))
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		else if (!moderate && moderate !== 0)
			return alertError({
				error: null,
				customMessage: "Field moderate is required"
			})
		else if (!vigorous && vigorous !== 0)
			return alertError({
				error: null,
				customMessage: "Field vigorous is required"
			})
		else if (moderate > vigorous)
			return alertError({
				error: null,
				customMessage: "Vigorous is lower than moderate one"
			})
		else if (soft > moderate)
			return alertError({
				error: null,
				customMessage: "Vigorous is lower than moderate one"
			})
		if (!activityId) {
			postActivity(activity)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Activity successfully created.",
						});
						history.push("/activities");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save activity.",
					});
				});
		} else {
			updateActivity(activityId, activity)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/activities");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!activity[element]) activity[element] = {};
			let newText = activity[element];
			newText[lang] = event.target.value;
			setActivity({ ...activity, [element]: newText });
		} else
			setActivity({
				...activity,
				[element]: event.target.value,
			});
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full name"
					value={
						(activity.fullName && activity.fullName[lang]) || ""
					}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
				<CardHeader title={activity?._id?'Activity: ' + (activity.fullName?activity.fullName.es:''):'New activity'}>
					<div className="mt-5">
						<Button
							onClick={() => {
								if (changes > 0) setOpenConfirmDialog(1);
								else history.push("/activities");
							}}
							variant="outlined"
							style={{ marginRight: "20px" }}
						>
							Back
						</Button>
						<Button
							onClick={() => saveActivity()}
							variant="outlined"
							color="primary"
							style={{ marginRight: "20px" }}
						>
							Save activity
						</Button>
						<ConfirmDialog
							title={
								"Are you sure you want to go back? You will lose all your changes"
							}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								history.push("/activities")
							}}
						/>
						{activityId && user?.role.includes("admin") && (
							<>
								<MuiThemeProvider theme={theme}>
									<Button
										onClick={() => setOpenConfirmDialog(2)}
										variant="outlined"
										color="secondary"
										style={{ marginRight: "20px" }}
									>
										Delete activity
									</Button>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											marginLeft: "auto",
										}}
									></div>
								</MuiThemeProvider>

								<ConfirmDialog
									title={
										"Are you sure you want to delete this activity?"
									}
									open={openConfirmDialog === 2}
									setOpen={setOpenConfirmDialog}
									onConfirm={() => {
										deleteActivity(activityId)
											.then((res) => {
												if (
													res.status === 204 ||
													res.status === 200
												) {
													alertSuccess({
														title: "Deleted!",
														customMessage:
															"Activity deleted successfully",
													});
													history.push("/activities");
												}
											})
											.catch((error) => {
												alertError({
													error: error,
													customMessage:
														"Could not delete activity.",
												});
											});
									}}
								/>
							</>
						)}
					</div>
				</CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<h4>External intensity</h4>
						<br />
						<Row>
							<Col sm={4}>
								<TextField
									id={`moderate`}
									label="Moderate (METs)"
									value={activity?.moderate || ""}
									onChange={handleChange("moderate", null)}
									InputLabelProps={{
										shrink: true,
									}}
									required
									margin="normal"
									variant="standard"
									type="number"
									InputProps={{
										inputProps: {
											min: 0
										},
									}}
								/>
							</Col>
							<Col sm={4}>
								<TextField
									id={`vigorous`}
									label="Vigorous (METs)"
									value={activity?.vigorous || ""}
									onChange={handleChange("vigorous", null)}
									InputLabelProps={{
										shrink: true,
									}}
									required
									margin="normal"
									variant="standard"
									type="number"
									InputProps={{
										inputProps: {
											min: 0
										},
									}}
								/>
							</Col>
							<Col sm={4}>
								<TextField
									id={`soft`}
									label="Soft (METs)"
									value={activity?.soft || ""}
									onChange={handleChange("soft", null)}
									InputLabelProps={{
										shrink: true,
									}}
									required
									margin="normal"
									variant="standard"
									type="number"
									InputProps={{
										inputProps: {
											min: 0
										},
									}}
								/>
							</Col>
						</Row>
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={activity.active}
									onChange={() =>
										setActivity({
											...activity,
											active: !activity.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
				</Card>
			</>
		);
}
