import {
	TextField,
	createMuiTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";

export default function MyAutocomplete(props) {
	const { options, getOptionLabel, value, onChange, placeholder, label, style } = props;
	
    return (
        <>
            <Autocomplete
                style={style}
                disablePortal
                disableClearable
                options={ options }
                getOptionLabel={(option) =>{
                    return getOptionLabel(option)
                }}
                value={ value }
                onChange={(event, selected) => {
                    onChange(event, selected)
                }}
                renderOption={(option, props) => (
                    <li
                        {...props}
                        style={{
                            color: props?.selected ? 'gray' : 'inherit',
                            opacity: props?.selected ? 0.5 : 1,
                        }}
                    >
                        {getOptionLabel(option)}
                    </li>
                )}
                renderInput={(params) => (
                <TextField
                    {...params}
                    margin="normal"
                    variant="standard"
                    color="green"
                    InputLabelProps={{
                    shrink: true,
                    }}
                    required
                    placeholder={placeholder}
                    label={label}
                />
                )}
            />
        </>
    );
}
