import { getFoodById } from "../api/food/index"
import { deleteFoodPlateStructure } from "../api/foodPlateStructure";
import { alertError } from "./logger";

export function foodCalcs(food) {
    const grams = parseFloat(food?.grams) || 0; 

    return {
        kcals: food?.food?.kcals*grams/100,
        proteins: food?.food?.proteins*grams/100,
		carbohydrates: food?.food?.carbohydrates*grams/100,
		fats: food?.food?.fats*grams/100,
		fiber: food?.food?.fiber*grams/100
    }
}

export function valueCalc(value, grams) {
    return parseFloat(value)*parseFloat(grams)/100;
}

export async function recipeCalcs(recipe) {
    const foodsIds = [...recipe.foods];

    let datt = [];
    let foods = [];

    for (let i = 0; i < foodsIds?.length; ++i) {
        const promise = getFoodById(foodsIds[i].foodId)
            .then((res) => {
                if (res.status === 200) {
                    const food = res.data;
                    foods.push({...food, grams: foodsIds[i].grams});
                }   
            })
            .catch((error) => {
                console.log(error)
            })
        datt.push(promise);
    }

    let nutritionalValues = {
        kcals: 0,
        proteins: 0,
        carbohydrates: 0,
        fats: 0,
        fiber: 0
    }
    
    await Promise.all(datt)
        .then(() => {
            nutritionalValues = foods.reduce((values, food) => {
                const gr = food?.grams;
                return {
                    kcals: parseFloat(values.kcals + valueCalc(food?.kcals, gr)),
                    proteins: parseFloat(values.proteins + valueCalc(food?.proteins, gr)),
                    carbohydrates: parseFloat(values.carbohydrates + valueCalc(food?.carbohydrates, gr)),
                    fats: parseFloat(values.fats + valueCalc(food?.fats, gr)),
                    fiber: parseFloat(values.fiber + valueCalc(food?.fiber, gr))
                    
                };
            }, {kcals: 0, proteins: 0, carbohydrates: 0, fats: 0, fiber: 0})
        })

    return nutritionalValues;
}

const emptyNtValues = {
    kcals: 0,
    carbohydrates: 0,
    fats: 0,
    fiber: 0,
    proteins: 0
}

export function activityCalcs(activity, intensityParams, patient) {
    if (!patient) return {...emptyNtValues};
    let mets = 0, intensity = 0;
    const type = activity?.typeOfActivity;
    switch(type) {
        case "Running":
            intensity = parseFloat(intensityParams?.speed || 0);
            mets = (0.0046 * intensity*intensity*intensity ) - 
                (0.1802 * intensity*intensity) + 
                (3.1192 * intensity) - 7.811;
            break;
        case "Bicicleta":
            intensity = parseFloat(intensityParams?.power || 0);
            mets = (0.0419 * intensity) + 2.2728;
            break;
        case "Trialrunning":
            break;
        default:
            intensity = parseFloat(activity?.activity[activity?.intensityType] || 0);
            mets = intensity;
            break;
    }

    if (intensity === 0) return {...emptyNtValues};

    const correctedMets = mets * (3.5 / patient?.tmbmlkgmin);
    const adjustedMets = mets + ((correctedMets - mets) * (mets >= 6 ? 1 : ((mets - 1) / 5)));

    const kcalMin = 0.0175 * adjustedMets * patient?.actualWeight;
    const kcals = kcalMin * parseFloat(intensityParams?.time);

    // console.log(intensity, mets, correctedMets, adjustedMets, kcalMin, kcals)

    return {
        kcals: kcals,
        carbohydrates: 0,
        fiber: 0,
        fats: 0,
        proteins: 0
    };
}

async function blockRecipeFoodCalcs(nt, block) {
    let nutritionalValues = {...nt};
    if (block?.intake?.recipes?.length > 0) {
        const recipeKcals = await Promise.all(
            block.intake.recipes.map(async (recipe) => {
            try {
                const res = !recipe?.extraMeal ? await recipeCalcs(recipe.recipe) :
                        {kcals: 0, carbohydrates: 0, fiber: 0, fats: 0, proteins: 0};
                return res;
            } catch (error) {
                console.log(error);
                return 0;
            }
            })
        );
        nutritionalValues = recipeKcals.reduce((total, nt) => {
            return sumValues(total, nt)}, {
            kcals: nutritionalValues.kcals, 
            carbohydrates: nutritionalValues.carbohydrates, 
            fiber: nutritionalValues.fiber, 
            fats: nutritionalValues.fats, 
            proteins: nutritionalValues.proteins
            
        });
    }
    if (block?.intake?.foods?.length > 0) nutritionalValues = block.intake?.foods?.reduce((total, food) => {
        if (!food?.extraMeal) {
            const nt = foodCalcs(food);
            return sumValues(total, nt)
        }
        else return total;
        }, {
            kcals: nutritionalValues.kcals, 
            carbohydrates: nutritionalValues.carbohydrates, 
            fiber: nutritionalValues.fiber, 
            fats: nutritionalValues.fats, 
            proteins: nutritionalValues.proteins
    });

    return {...nutritionalValues, 
        isOneFood: (
            nutritionalValues.kcals ||
            nutritionalValues.carbohydrates ||
            nutritionalValues.fiber ||
            nutritionalValues.fats || 
            nutritionalValues.proteins
        )
    };
}

const cubeValues = {
    cubeVegetables: {kcals: 28.5, carbohydrates: 4, fiber: 0, fats: 0.5, proteins: 2},
    cubeStarches: {kcals: 68.5, carbohydrates: 14, fiber: 0, fats: 0.5, proteins: 2},
    cubeProteins: {kcals: 46, carbohydrates: 0, fiber: 0, fats: 2, proteins: 7},
    cubeFats: {kcals: 45, carbohydrates: 0, fiber: 0, fats: 5, proteins: 0}
}

function cubeCalc(value, name, total) {
    if (value <= 0) return total;
    let cub = {...cubeValues[name]};
    cub.kcals = value * cub.kcals;
    cub.carbohydrates *= value;
    cub.fiber *= value;
    cub.fats *= value;
    cub.proteins = value * cub.proteins;
    return sumValues(total, cub);
}

export async function errorCalc(block) {
    let nutritionalValues = {kcals: 0, carbohydrates: 0, fiber: 0, fats: 0, proteins: 0};
    let errorValues = {kcals: false, carbohydrates: false, fiber: false, fats: false, proteins: false};
    
    // let nutritionalCubeValues = {kcals: 0, carbohydrates: 0, fiber: 0, fats: 0, proteins: 0};
    // const intake = block.intake;
    // nutritionalCubeValues = cubeCalc(parseFloat(intake?.cubeVegetables) || 0, "cubeVegetables", nutritionalCubeValues);
    // nutritionalCubeValues = cubeCalc(parseFloat(intake?.cubeStarches) || 0, "cubeStarches", nutritionalCubeValues);
    // nutritionalCubeValues = cubeCalc(parseFloat(intake?.cubeProteins) || 0, "cubeProteins", nutritionalCubeValues);
    // nutritionalCubeValues = cubeCalc(parseFloat(intake?.cubeFats) || 0, "cubeFats", nutritionalCubeValues);

    const kcals = block?.kcals;//nutritionalCubeValues?.kcals;
    const carbohydrates = block?.carbohydrates;
    const fiber = block?.fiber;
    const fats = block?.fats;
    const proteins = block?.proteins;
    
    if (block?.intake?.recipes?.length > 0 || block?.intake?.foods?.length > 0) {
        nutritionalValues = await blockRecipeFoodCalcs(nutritionalValues, block);
        
        if (nutritionalValues.isOneFood) {
            if (Math.abs(kcals - nutritionalValues.kcals) >= 20) errorValues.kcals = true;
            if (Math.abs(carbohydrates - nutritionalValues.carbohydrates) >= 20) errorValues.carbohydrates = true;
            if (Math.abs(fats - nutritionalValues.fats) >= 20) errorValues.fats = true;
            if (Math.abs(proteins - nutritionalValues.proteins) >= 20) errorValues.proteins = true;
            // if (Math.abs(fiber - nutritionalValues.fiber) >= 20) return true;
        }
    }

    return errorValues;
}

export function blockIsMain(block) {
    if (!block?.isIntake) return false;
    const intake = block?.intake;
    if (intake?.isSnack === '0' || !intake?.isSnack) return true;
    return false;
}

export async function blockCalc(block, patient) {
    let nutritionalValues = {kcals: 0, carbohydrates: 0, fiber: 0, fats: 0, proteins: 0};
    
    async function calcFoodAndRecipes(isMain) {
        if (block?.intake?.recipes?.length > 0) {
            const recipeKcals = await Promise.all(
                block.intake.recipes.map(async (recipe) => {
                try {
                    const res = await recipeCalcs(recipe.recipe);
                    if (isMain && !recipe?.extraMeal) return nutritionalValues;
                    return res;
                } catch (error) {
                    console.log(error);
                    return 0;
                }
                })
            );
            nutritionalValues = recipeKcals.reduce((total, nt) => {
                return sumValues(total, nt)}, {
                kcals: nutritionalValues.kcals, 
                carbohydrates: nutritionalValues.carbohydrates, 
                fiber: nutritionalValues.fiber, 
                fats: nutritionalValues.fats, 
                proteins: nutritionalValues.proteins
                
            });
        }
        if (block?.intake?.foods?.length > 0) nutritionalValues = block.intake?.foods?.reduce((total, food) => {
            if (isMain && !food.extraMeal) return total;
            const nt = foodCalcs(food);
            return sumValues(total, nt)}, {
                kcals: nutritionalValues.kcals, 
                carbohydrates: nutritionalValues.carbohydrates, 
                fiber: nutritionalValues.fiber, 
                fats: nutritionalValues.fats, 
                proteins: nutritionalValues.proteins
        });
    }
    // console.log(block)
    if (block?.isIntake) {
        const intake = block?.intake;
        const isMain = blockIsMain(block);
        if (isMain) {
            nutritionalValues = cubeCalc(parseFloat(intake?.cubeVegetables) || 0, "cubeVegetables", nutritionalValues);
            nutritionalValues = cubeCalc(parseFloat(intake?.cubeStarches) || 0, "cubeStarches", nutritionalValues);
            nutritionalValues = cubeCalc(parseFloat(intake?.cubeProteins) || 0, "cubeProteins", nutritionalValues);
            nutritionalValues = cubeCalc(parseFloat(intake?.cubeFats) || 0, "cubeFats", nutritionalValues);
        }
        await calcFoodAndRecipes(isMain)
    } else {
        const activity = block?.exercise?.activities;
        if (activity?.intensities?.length > 0) nutritionalValues = activity?.intensities?.reduce((total, intensity) => {
            const nt = activityCalcs(activity, intensity, patient);
            return sumValues(total, nt)
            }, {
                kcals: nutritionalValues.kcals, 
                carbohydrates: nutritionalValues.carbohydrates, 
                fiber: nutritionalValues.fiber, 
                fats: nutritionalValues.fats, 
                proteins: nutritionalValues.proteins
            });        
    }

    return nutritionalValues;
}

function sumValues(total, nt) {
    return {
        kcals: total.kcals + nt.kcals, 
        carbohydrates: total.carbohydrates + nt.carbohydrates, 
        fiber: total.fiber + nt.fiber, 
        fats: total.fats + nt.fats, 
        proteins: total.proteins + nt.proteins
    }
}

export function reCalcNutritionalValues(blocks) {
    let data = {};
    
    let kcals = 0;
    let carbohydrates = 0;
    let protein = 0;
    let fats = 0;
    let fiber = 0;
    let fatskg = 0;
    let fatspercent = 0;
    
    for (let i = 0; i < blocks?.length; ++i) {
        const block = blocks[i];
        const blockForw = (i+1 !== blocks?.length) ? blocks[i+1] : null;
        if (block?.isIntake) {
            if (block?.name === blockForw?.name && !blockIsMain(block) && !blockIsMain(blockForw)) {
                let kcalsSum = block?.kcals;
                let carbohydratesSum = block?.carbohydrates;
                let proteinSum = block?.proteins;
                let fatsSum = block?.fats;
                let fiberSum = block?.fiber;
                let counter = 1;

                const name = block.name;
                for (let j = i+1; j < blocks?.length; ++j) {
                    const actBlock = blocks[j];
                    if (actBlock?.name === name && !blockIsMain(actBlock) && !blockIsMain(actBlock)) {
                        kcalsSum += actBlock.kcals;
                        carbohydratesSum += actBlock.carbohydrates;
                        proteinSum += actBlock.proteins;
                        fatsSum += actBlock.fats;
                        fiberSum += actBlock.fiber;
                        counter++;
                    } else break;
                }
                
                kcals += kcalsSum/counter;
                carbohydrates += carbohydratesSum/counter;
                protein += proteinSum/counter;
                fats += fatsSum/counter;
                fiber += fiberSum/counter;
                i += counter-1;
            } else {
                kcals += block?.kcals;
                carbohydrates += block?.carbohydrates;
                protein += block?.proteins;
                fats += block?.fats;
                fiber += block?.fiber;
            }
        } else {
            kcals = kcals - block?.kcals;
        }
    }

    data.kcals = kcals;
    data.carbohydrates = carbohydrates;
    data.protein = protein;
    data.fats = fats;
    data.fiber = fiber;
    data.fatskg = fatskg;
    data.fatspercent = fatspercent;
    return data;
}

export async function getDataBlocks(blocks, patient) {

    function sortByNumber(a, b) {
        if (a.number < b.number) return -1;
        if (a.number > b.number) return 1;
        return 0;
    }

    function getInformation(block, calcs) {
        let elem = {};

        elem._id = block._id;
        elem.number = block.number;

        const tempDiv = document.createElement("div");
        tempDiv.style.display = "none";
        tempDiv.innerHTML = block?.description || "There is no description";
        const plainText = tempDiv.textContent || tempDiv.innerText;
        elem.description = plainText;

        elem.kcals = calcs?.kcals;
        elem.carbohydrates = calcs?.carbohydrates;
        elem.proteins = calcs?.proteins;
        elem.fats = calcs?.fats;
        elem.fiber = calcs?.fiber;

        return {...elem};
    }

    let data = [];

    let kcals = 0;
    let proteins = 0;
    let carbohydrates = 0;
    let fats = 0;
    let fiber = 0;

    for (let i = 0; i < blocks.length; ++i) {
        let elem = {};
        const block = {...blocks[i]};
        
        const calcs = await blockCalc(block, patient);
        elem = getInformation(block, calcs);

        const blockForw = (i+1 !== blocks?.length) ? blocks[i+1] : null;
        if (block?.isIntake) {
            if (block?.name === blockForw?.name && !blockIsMain(block) && !blockIsMain(blockForw)) {
                let kcalsSum = elem?.kcals;
                let carbohydratesSum = elem?.carbohydrates;
                let proteinSum = elem?.proteins;
                let fatsSum = elem?.fats;
                let fiberSum = elem?.fiber;
                let counter = 1;

                const name = block.name;
                for (let j = i+1; j < blocks?.length; ++j) {
                    const actBlock = blocks[j];
                    if (actBlock?.name === name && !blockIsMain(actBlock) && !blockIsMain(actBlock)) {
                        const calcsAct = await blockCalc(actBlock, patient);
                        const elem2 = getInformation(actBlock, calcsAct);

                        kcalsSum += calcsAct.kcals;
                        carbohydratesSum += calcsAct.carbohydrates;
                        proteinSum += calcsAct.proteins;
                        fatsSum += calcsAct.fats;
                        fiberSum += calcsAct.fiber;

                        data.push(elem2);
                        counter++;
                    } else break;
                }
                
                kcals += kcalsSum/counter;
                carbohydrates += carbohydratesSum/counter;
                proteins += proteinSum/counter;
                fats += fatsSum/counter;
                fiber += fiberSum/counter;
                i += counter-1;
            } else {
                kcals += calcs?.kcals;
                carbohydrates += calcs?.carbohydrates;
                proteins += calcs?.proteins;
                fats += calcs?.fats;
                fiber += calcs?.fiber;
            }
        } else {
            kcals = kcals - calcs?.kcals;
        }
     
        data.push(elem);
    }

    data.sort(sortByNumber);
    return {
        data: data,
        kcals: kcals,
        proteins: proteins,
        carbohydrates: carbohydrates,
        fats: fats,
        fiber: fiber
    };
}

export function styleFormat(block) {
    return (block?.isIntake? (!block?.intake.isSnack || block?.intake?.isSnack === '0')?
        { //MAIN
            backgroundColor: '#ABF5FF'
        } : 
        { //SNACK
            backgroundColor: '#EBF6DE'

        } : 
        { //ACTIVITY
            backgroundColor: ' #FFFFA3'
        });
}

export async function deleteStructureRecipe(recipe) {
    if (!recipe?._id) return true;
    const res = await deleteFoodPlateStructure(recipe._id)
        .catch((error) => {
            alertError({
                error: error,
                customMessage: "Could not delete recipe"
            })
            return false;
        })
    
    if (res) return true;
    return false;
}

export async function deleteBlockRecipesFromBD(structure, index) {
    const block = structure?.blocks?.find((x) => x.number === index);

    if (block?.isIntake) {
        if (block?.intake?.recipes?.length > 0) {
            const recipes = [...block?.intake?.recipes];
            for (let i = 0; i < recipes?.length; ++i) {
                const recipe = recipes[i];
                const res = await deleteStructureRecipe(recipe?.recipe);
                if (!res) return false;
            }
        }
    }

    return true;
}

export async function deleteAllBlocksRecipesFromBD(structure) {
    for (let i = 0; i < structure?.blocks?.length; ++i) {
        const actB = structure?.blocks[i];
        const res = await deleteBlockRecipesFromBD(structure, actB.number);
        if (!res) return false;
    }
    return true;
}

export function getDataBlocksTable(blocks) {
    function sortByNumber(a, b) {
        if (a.number < b.number) return -1;
        if (a.number > b.number) return 1;
        return 0;
    }

    let data = [];

    for (let i = 0; i < blocks.length; ++i) {
        let elem = {};
        const block = blocks[i];

        elem._id = block._id;
        elem.number = block.number;

        const tempDiv = document.createElement("div");
        tempDiv.style.display = "none";
        tempDiv.innerHTML = block?.description || "There is no description";
        const plainText = tempDiv.textContent || tempDiv.innerText;
        elem.description = plainText;

        elem.kcals = block?.kcals || 0;
        elem.carbohydrates = block?.carbohydrates || 0;
        elem.fats = block?.fats || 0;
        elem.fiber = block?.fiber || 0;
        elem.proteins = block?.proteins || 0;

        data.push(elem);
    }

    data.sort(sortByNumber);

    return data;
}

export function calcUnitsNt(value, type, nt, kcals, weight) {
    const number = parseFloat(value);
    const numKcals = parseFloat(kcals);
    if (type === "g/kg") return (number / parseFloat(weight)).toFixed(1);
    else {
        if (nt === "carbohydrates" || nt === "proteins") 
            return ((number * 4 / numKcals) * 100).toFixed(1);
        else return ((number * 9 / numKcals) * 100).toFixed(1);
    }
}

export const activityTypes = ["Running", "Bicicleta", "Trialrunning", "Otros"];
export const intensityTypes = ["Moderate", "Vigorous", "Soft"];