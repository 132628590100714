import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { alertError } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../MultilanguageTabBlock";
import { Autocomplete } from "@material-ui/lab";
import { Col, Row } from "react-bootstrap";
import MyAutocomplete from "../../MyAutocomplete";
import { activityCalcs, intensityTypes } from "../../../../utils/structuresCalcs";

function getEmptyIntensity() {
	return {
		kcals: 0,
        speed: 0,
        time: 0,
        power: 0,
        intensityType: ""
	};
}

const EditBlockIntensityDialog = (props) => {
	const { title, data, open, setOpen, onSave, type, activity, activities, patient } = props;

	const [intensity, setIntensity] = useState(getEmptyIntensity());
    const [completeActivity, setCompleteActivity] = useState(null);
    const [firstIntensity, setFirstIntensity] = useState(null);
	const [refresh, setRefresh] = useState(false);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	useEffect(() => {
		if (!open || !data) setIntensity(getEmptyIntensity());
		else {
            const completeActivity = activities.find((x) => x._id === activity._id);
            setCompleteActivity(completeActivity);
            const intensity = data;
            setFirstIntensity(intensity);
            setIntensity(intensity);
        }
	}, [data, open]);

    function refreshCalcs(intensity) {
        const calcs = activityCalcs(activity, intensity, patient);
        setIntensity({
            ...intensity,
            kcals: calcs.kcals
        });
    }

	const handleChange = (element) => (event) => {
		if (event.target.value === " ") return;
		//Siempre se modifican las "kcals", ya que todos los campos afectan
        const newIntensity = {
            ...intensity,
            [element]: event.target.value
        }
        refreshCalcs(newIntensity);
        setRefresh(true);
	};

	return (
		<Dialog disableBackdropClick={true}
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>

            <Row>
                <Col>
                    {type === "Running" ? (
                        <TextField
                            id={`speed`}
                            label="Speed (km/h)"
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                            onChange={handleChange('speed')}
                            value={intensity?.speed || 0}
                            margin="normal"
                            variant="standard"
                            type="number"
                        />
                    ) : type === "Bicicleta" ? (
                        <TextField
                            id={`power`}
                            label="Power (W)"
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                            onChange={handleChange('power')}
                            value={intensity?.power || 0}
                            margin="normal"
                            variant="standard"
                            type="number"
                        />
                    ) : type === "Trialrunning" ? (
                        <TextField
                            id={`power`}
                            label="Power (W)"
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                            onChange={handleChange('power')}
                            value={intensity?.power || 0}
                            margin="normal"
                            variant="standard"
                            type="number"
                        />
                    ) : (
                        <MyAutocomplete
                            options={ intensityTypes }
                            getOptionLabel={(option) =>
                                option
                            }
                            value={ intensity?.intensityType || null }
                            onChange={(event, selected) => {
                                const newIntensity = {...intensity, intensityType: selected};
                                refreshCalcs(newIntensity);
                                setRefresh(true)
                            }}
                            placeholder="Select level"
                            label={"Level"}
                        />
                    )}
                    
                </Col>
                <Col>
                    <TextField
                        id={`time`}
                        label="Time (in minutes)"
                        InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }}
                        onChange={handleChange('time')}
                        value={intensity?.time || 0}
                        margin="normal"
                        variant="standard"
                        type="number"
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <TextField
                        id={`kcals`}
                        label="KCAL"
                        inputProps={{ readOnly: true }}
                        value={intensity?.kcals || 0}
                        margin="normal"
                        variant="standard"
                        type="number"
                        className='readonly'
                    />
                </Col>
            </Row>

				<br />
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setIntensity(getEmptyIntensity());
                        setCompleteActivity(null);
                        setFirstIntensity(null);
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (!(intensity?.intensityType || intensity?.power || intensity?.speed)) {
							alertError({
								error: null,
								customMessage:
									"The intensity is required",
							});
                        }
                        else if (!intensity?.time && intensity?.time !== 0) {
                            alertError({
								error: null,
								customMessage:
									"The time is required",
							});
                        } else {
							setOpen(false);
							onSave(intensity, firstIntensity);
                            setFirstIntensity(null)
                            setCompleteActivity(null)
							setIntensity(getEmptyIntensity());
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};


export default EditBlockIntensityDialog;